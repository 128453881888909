import React, { useState } from 'react'
import Heading1 from '../../Reusable Components/Headings/Heading1'
import Fade from 'react-reveal/Fade';
import './Offerings.css'
import SubHeading from '../../Reusable Components/Headings/SubHeading';
import image1 from './Assets/extraction.jpeg'
import image2 from './Assets/datautilization.jpeg'
import image3 from './Assets/email.jpeg'
import image4 from './Assets/task.jpeg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBorderNone, faSearch, faUserCog, faUsers } from '@fortawesome/free-solid-svg-icons';

function Offerings() {

    const [videoboximage, setVideoboximage] = useState(image1);


    function Changeimage1() {
        setVideoboximage(image1)
        document.getElementById("videoarea").style.transition = ".2s ease";
    }

    function Changeimage2() {
        setVideoboximage(image2)
        document.getElementById("videoarea").style.transition = ".2s ease";
    }

    function Changeimage3() {
        setVideoboximage(image3)
        document.getElementById("videoarea").style.transition = ".2s ease";
    }

    function Changeimage4() {
        setVideoboximage(image4)
        document.getElementById("videoarea").style.transition = ".2s ease";
    }
    return (
        <div className='container OfferingsContainer'>

            <div>
                <SubHeading>
                    <Fade top cascade>
                        What We Offer
                    </Fade>
                </SubHeading>
                <hr className='headingLine' />
            </div>

            <div className=' OfferingSplit'>

                <div className=' col-md-5 col-sm-12 OfferingAlignment OfferHover'>

                    <Fade right cascade>

                        <div className='OfferingCardsContainer OfferHover' onMouseOver={Changeimage1}>

                            <div className='d-flex w-100'>
                                <div className='offeringIconContainer' style={{ width: "5rem" }}>
                                    <FontAwesomeIcon className='OfferingIcon' icon={faBorderNone} style={{ color: "var(--orange)" }} />
                                </div>
                                <div className='d-flex flex-column justify-content-left' style={{ width: "80%" }}>

                                    <Heading1
                                        color="var(--blue)"
                                        size="1.4rem"
                                        weight="350"
                                        JFcontent="left"
                                        marginBottom="3px"

                                        style={{ marginTop: "0.8rem" }}
                                    >Data Extraction </Heading1>

                                    <Heading1
                                        color="var(--)"
                                        paddingInline=""
                                        paddingBlock=""
                                        size=".9rem"
                                        weight="350"
                                        JFcontent="left"
                                        marginBottom="15px"

                                        style={{ paddingRight: "15px" }}
                                    >
                                        Proprietary OCR extracts data automatically from any uploaded document where a unique key value linking trained model fills information automatically.                                    </Heading1>
                                </div>

                            </div>

                        </div>

                        <div className='OfferingCardsContainer' onMouseOver={Changeimage2}>

                            <div className='d-flex w-100'>
                                <div className='offeringIconContainer' style={{ width: "5rem" }}>
                                    <FontAwesomeIcon className='OfferingIcon' icon={faUserCog} style={{ color: "var(--orange)" }} />
                                </div>
                                <div className='d-flex flex-column justify-content-left' style={{ width: "80%" }}>

                                    <Heading1
                                        color="var(--blue)"
                                        size="1.4rem"
                                        weight="350"
                                        JFcontent="left"
                                        marginBottom="3px"

                                        style={{ marginTop: "0.8rem" }}
                                    >Data Utilization </Heading1>

                                    <Heading1
                                        color="var(--)"
                                        paddingInline=""
                                        paddingBlock=""
                                        size=".9rem"
                                        weight="350"
                                        JFcontent="left"
                                        marginBottom="15px"

                                        style={{ paddingRight: "15px", flexDirection: "column" }}
                                    >
                                        A state of the art Machine learning models with the power of NLP utilizes thousands of project data and information and make it accessible within one second.                                    </Heading1>
                                </div>

                            </div>

                        </div>

                        <div className='OfferingCardsContainer' onMouseOver={Changeimage3}>

                            <div className='d-flex w-100'>
                                <div className='offeringIconContainer' style={{ width: "5rem" }}>
                                    <FontAwesomeIcon className='OfferingIcon' icon={faUsers} style={{ color: "var(--orange)" }} />
                                </div>
                                <div className='d-flex flex-column justify-content-left' style={{ width: "80%" }}>

                                    <Heading1
                                        color="var(--blue)"
                                        size="1.4rem"
                                        weight="350"
                                        JFcontent="left"
                                        marginBottom="3px"

                                        style={{ marginTop: "0.8rem" }}
                                    > Integrations</Heading1>

                                    <Heading1
                                        color="var(--)"
                                        paddingInline=""
                                        paddingBlock=""
                                        size=".9rem"
                                        weight="350"
                                        JFcontent="left"
                                        marginBottom="15px"

                                        style={{ paddingRight: "15px" }}
                                    >
                                        Integrate with other software’s (outlook for emails) and utilize the platform powers within your emails.                                   </Heading1>
                                </div>

                            </div>

                        </div>

                        <div className='OfferingCardsContainer' onMouseOver={Changeimage4}>

                            <div className='d-flex w-100'>
                                <div className='offeringIconContainer' style={{ width: "5rem" }}>
                                    <FontAwesomeIcon className='OfferingIcon' icon={faSearch} style={{ color: "var(--orange)" }} />
                                </div>
                                <div className='d-flex flex-column justify-content-left' style={{ width: "80%" }}>

                                    <Heading1
                                        color="var(--blue)"
                                        size="1.4rem"
                                        weight="350"
                                        JFcontent="left"
                                        marginBottom="3px"

                                        style={{ marginTop: "0.8rem" }}
                                    > Task Management </Heading1>

                                    <Heading1
                                        color="var(--)"
                                        paddingInline=""
                                        paddingBlock=""
                                        size=".9rem"
                                        weight="350"
                                        JFcontent="left"
                                        marginBottom="15px"

                                        style={{ paddingRight: "15px" }}
                                    >
                                        Collaborate, coordinate, manage and complete tasks easier and simpler.                                    </Heading1>
                                </div>

                            </div>

                        </div>

                    </Fade>

                </div>

                <div className=' col-md-5 col-sm-12 d-flex flex-column align-items-center justify-content-between'>

                    <Fade top>
                        <div className='VideoBox' id="videoarea" style={{
                            backgroundImage: `url(${videoboximage})`,
                        }}>

                        </div>
                    </Fade>
                </div>

            </div>
        </div>
    )
}

export default Offerings
