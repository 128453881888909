import React from 'react'
import WhyCards from './Sections/WhyCards'
import './WhyStonAI.css'
import Fade from 'react-reveal/Fade';
import SubHeading from '../../Reusable Components/Headings/SubHeading';
import { faBraille, faChartLine, faClock, faCrosshairs, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';

const WhyCardDetails = [
    {
        id: 1,
        title: 'Time',
        details: <p><strong>90% faster</strong> than human and <strong>30%</strong> staff time <strong>utilization</strong>. </p>,
        image: faClock,

    },
    {
        id: 2,
        title: 'Accuracy',
        details: <p><strong>90%</strong> higher <strong>accuracy</strong> than human </p>,
        image: faCrosshairs,
    },

    {
        id: 3,
        title: 'Business Intelligence',
        details: <p>Customized and live <strong>KPI’s</strong> and task management <strong>dashboard</strong></p>,
        image: faBraille,
    },

    {
        id: 4,
        title: 'Efficiency',
        details: <p><strong>Collaborate, manage,</strong> and <strong>complete</strong> tasks faster, simpler and more organized</p>,
        image: faChartLine,
    },

    {
        id: 5,
        title: 'Cost Saving',
        details: <p><strong>Reduces</strong> project management <strong>costs</strong> by up to 20%</p>,
        image: faMoneyBillAlt,
    },





]

function WhyStonAI() {
    return (
        <div className='whystonaiContainer container'>

            <div>

                <SubHeading variant="">
                    <Fade top cascade>
                        Why stonAI
                    </Fade>
                </SubHeading>


                <hr className='headingLine' />
            </div>

            <div className='CardsDiv d-flex flex-wrap p-0 align-items-start '
                style={{ width: "100vw", gap: "1rem" }}>
                <Fade bottom>
                    {
                        WhyCardDetails.map((data) => {
                            return (
                                <WhyCards key={data.id}
                                    title={data.title}
                                    details={data.details}
                                    image={data.image}
                                />
                            );
                        })
                    }
                </Fade>
            </div>
        </div>
    )
}

export default WhyStonAI
